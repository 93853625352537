// export const cartItemsTotalPrice = (items, { discountInPercent = 0 } = {}) => {
export const cartItemsTotalPrice = (items, coupon = null) => {
  if (items === null || items.length === 0) return 0;
  const itemCost = items.reduce((total, item) => {
    if (item.ws_price_aftrer_off) {
      return total + item.ws_price_aftrer_off * item.quantity;
    }
    return total + item.ws_price_aftrer_off * item.quantity;
  }, 0);
  // const discountRate = 1 - discountInPercent;
  const discount = coupon
    ? (itemCost * Number(coupon.discountInPercent)) / 100
    : 0;
  // itemCost * discountRate * TAX_RATE + shipping;
  // return itemCost * discountRate;
  return itemCost - discount;
};

export const totalDeliveryCost = (items) => {
  if (items === null || items.length === 0) return 0;
  const deliveryCost = items.reduce((total, item) => {
    if (item.delivery_charge) {
      return total + item.delivery_charge * item.quantity;
    }
    return total + item.delivery_charge * item.quantity;
  }, 0);


  // return deliveryCost;
  return deliveryCost;
};
// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
  const existingCartItemIndex = state.items.findIndex(
    (item) => item.product_id === action.payload.product_id
  );

  // console.log('action.payload.quantity',action.payload.quantity);
  if (existingCartItemIndex > -1) {

    const newState = [...state.items];

    newState[existingCartItemIndex].quantity = parseFloat(newState[existingCartItemIndex].quantity) + parseFloat(newState[existingCartItemIndex].increase_by);

    return newState;
  }

  return [...state.items, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
  return state.items.reduce((acc, item) => {
    if (item.product_id === action.payload.product_id) {

      //const newQuantity = parseFloat(item.quantity) - parseFloat(item.increase_by);
      const newQuantity = `${parseFloat(item.min_allowed
        ) > 0 && parseFloat(item.min_allowed
          ) === parseFloat(item.quantity)
        ? 0
        : (parseFloat(item.quantity) - parseFloat(item.increase_by))}`;
      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];
    }
    return [...acc, item];
  }, []);
};

const clearItemFromCart = (state, action) => {
  return state.items.filter(
    (item) => item.product_id !== action.payload.product_id
  );
};

export const reducer = (state, action) => {
  // console.log(action.payload.allProduct);
  switch (action.type) {
    case 'SAVE_PRODUCT':
      return { ...state, productInfo: action.payload.allProduct };
    case 'REHYDRATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_CART':
      //console.log(!state.isOpen);
      return { ...state, isOpen: !state.isOpen };
    case 'ADD_ITEM':
      return { ...state, items: addItemToCart(state, action) };
    case 'REMOVE_ITEM':
      return { ...state, items: removeItemFromCart(state, action) };
    case 'CLEAR_ITEM_FROM_CART':
      return { ...state, items: clearItemFromCart(state, action) };
    case 'CLEAR_CART':
      return { ...state, items: [] };
    case 'APPLY_COUPON':
      return { ...state, coupon: action.payload };
    case 'REMOVE_COUPON':
      return { ...state, coupon: null };
    case 'TOGGLE_RESTAURANT':
      return { ...state, isRestaurant: !state.isRestaurant };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
