export const baseURL = 'https://www.itrading.com.bd/api/getWebsiteInfo/';
export const PRODUCTINFO_URL = 'https://www.itrading.com.bd/api/productInformation/';
export const subTypeURL = 'https://www.itrading.com.bd/api/subCategoryProduct/';
export const SEND_OTP_URL = 'https://www.itrading.com.bd/api/sendSmsWs/';
export const REGISTER_CUSTOMER_URL = 'https://www.itrading.com.bd/api/registerWSCustomer/';

export const API_KEY = 'ba8dd74c58444d83';
export const IMAGE_URL = "https://www.itrading.com.bd/pos/assets/product_image/";
export const PRODUCT_SEARCH_URL = 'https://www.itrading.com.bd/api/searchItem/';

export const OUTLET_URL = 'https://www.goodrelations.com.bd/api/getOutletInfo';
export const START_URL = 'https://www.itrading.com.bd/api/getDashboardData/1';
export const SUBTYPE_ITEM_URL = 'https://www.itrading.com.bd/api/subTypeItemsInfo/';
export const CUSTOM_TYPE_URL = 'https://www.itrading.com.bd/api/customTypeItems/';
export const SEARCH_URL = 'https://www.itrading.com.bd/api/searchProduct';
export const PLACE_ORDER_URL = 'https://www.itrading.com.bd/api/processWsOrder';
export const MY_ORDER_URL = 'https://www.itrading.com.bd/api/getMyOrder/';
export const ORDER_DETAILS_URL = 'https://www.itrading.com.bd/api/getWsOrderDetails/';

export const STORAGE_URL = 'https://www.itrading.com.bd/pos/assets/product_image/';